import React from "react"
import { Box } from "@chakra-ui/core"

import SEO from "../components/boilerplate/seo"
import Heading from "../components/typography/Heading"
import Body from "../components/typography/Body"
import { getDynamicRedirect } from "../utils/redirects"

type NotFoundPageProps = {
  location: Location
}

const NotFoundPage = ({ location }: NotFoundPageProps) => {
  if (getDynamicRedirect(location).isRedirect) {
    return null
  }

  return (
    <Box pt="12rem" textAlign="center">
      <SEO title="404: Not found" />
      <Heading mb="3rem">404: NOT FOUND</Heading>
      <Body mb="6rem">
        You just hit a route that doesn&#39;t exist... the sadness.
      </Body>
    </Box>
  )
}

export default NotFoundPage
